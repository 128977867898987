/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalculationResultParamsDto } from '../models/CalculationResultParamsDto';
import type { IceCalculationRequestDto } from '../models/IceCalculationRequestDto';
import type { IceCalculationResponseDto } from '../models/IceCalculationResponseDto';
import type { IceCalculationResultDto } from '../models/IceCalculationResultDto';
import type { IceCalculationStatusDto } from '../models/IceCalculationStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IceCalculationAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody 
   * @returns IceCalculationResponseDto Success
   * @throws ApiError
   */
  public requestCalculation(
requestBody?: IceCalculationRequestDto,
): CancelablePromise<IceCalculationResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/ice-calculate',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param calculationUuid 
   * @returns IceCalculationStatusDto Success
   * @throws ApiError
   */
  public getCalculationStatus(
calculationUuid: string,
): CancelablePromise<IceCalculationStatusDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/ice-calculate/status/{calculationUuid}',
      path: {
        'calculationUuid': calculationUuid,
      },
    });
  }

  /**
   * @param calculationUuid 
   * @param requestBody 
   * @returns IceCalculationResultDto Success
   * @throws ApiError
   */
  public getCalculationResult(
calculationUuid: string,
requestBody?: CalculationResultParamsDto,
): CancelablePromise<IceCalculationResultDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/ice-calculate/results/{calculationUuid}',
      path: {
        'calculationUuid': calculationUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param modelUuid 
   * @param requestBody 
   * @returns IceCalculationResultDto Success
   * @throws ApiError
   */
  public getLastModelResult(
modelUuid: string,
requestBody?: CalculationResultParamsDto,
): CancelablePromise<IceCalculationResultDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/ice-calculate/results/model-last/{modelUuid}',
      path: {
        'modelUuid': modelUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param modelUuid 
   * @param requestBody 
   * @returns any Success
   * @throws ApiError
   */
  public downloadLastResult(
modelUuid: string,
requestBody?: CalculationResultParamsDto,
): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/ice-calculate/download/{modelUuid}',
      path: {
        'modelUuid': modelUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
