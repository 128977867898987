/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectionDto } from '../models/ConnectionDto';
import type { IntegrationRequestDto } from '../models/IntegrationRequestDto';
import type { IntegrationUpdateDto } from '../models/IntegrationUpdateDto';
import type { UpdateConnectionDto } from '../models/UpdateConnectionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConnectionsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ConnectionDto Success
   * @throws ApiError
   */
  public getConnections(): CancelablePromise<Array<ConnectionDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/connections',
    });
  }

  /**
   * @param connectionUuid 
   * @returns ConnectionDto Success
   * @throws ApiError
   */
  public getConnection(
connectionUuid: string,
): CancelablePromise<ConnectionDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/connections/{connection_uuid}',
      path: {
        'connection_uuid': connectionUuid,
      },
    });
  }

  /**
   * @param connectionUuid 
   * @param requestBody 
   * @returns ConnectionDto Success
   * @throws ApiError
   */
  public updateConnection(
connectionUuid: string,
requestBody?: UpdateConnectionDto,
): CancelablePromise<ConnectionDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/connections/{connection_uuid}',
      path: {
        'connection_uuid': connectionUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param connectionUuid 
   * @param requestBody 
   * @returns ConnectionDto Success
   * @throws ApiError
   */
  public connectIntegration(
connectionUuid: string,
requestBody?: IntegrationUpdateDto,
): CancelablePromise<ConnectionDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/connections/integration/{connection_uuid}',
      path: {
        'connection_uuid': connectionUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody 
   * @returns ConnectionDto Success
   * @throws ApiError
   */
  public requestIntegration(
requestBody?: IntegrationRequestDto,
): CancelablePromise<ConnectionDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/connections/request',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
