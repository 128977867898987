/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentCreateDto } from '../models/CommentCreateDto';
import type { CommentDto } from '../models/CommentDto';
import type { DashboardCountResponseDto } from '../models/DashboardCountResponseDto';
import type { DashboardGraph } from '../models/DashboardGraph';
import type { DifferenceTable } from '../models/DifferenceTable';
import type { InvoiceDto } from '../models/InvoiceDto';
import type { InvoicePostDto } from '../models/InvoicePostDto';
import type { InvoiceTableDto } from '../models/InvoiceTableDto';
import type { LineMatchingRowUpdateDTO } from '../models/LineMatchingRowUpdateDTO';
import type { LineMatchingTableDto } from '../models/LineMatchingTableDto';
import type { Options } from '../models/Options';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DashboardAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param projectUuid 
   * @returns InvoiceTableDto Success
   * @throws ApiError
   */
  public getInvoiceTable(
projectUuid: string,
): CancelablePromise<InvoiceTableDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard/invoice/{projectUuid}',
      path: {
        'projectUuid': projectUuid,
      },
    });
  }

  /**
   * @param requestBody 
   * @returns InvoiceDto Success
   * @throws ApiError
   */
  public createInvoice(
requestBody?: InvoicePostDto,
): CancelablePromise<InvoiceDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Dashboard/invoice',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param invoiceUuid 
   * @param requestBody 
   * @returns InvoiceDto Success
   * @throws ApiError
   */
  public updateInvoice(
invoiceUuid: string,
requestBody?: InvoiceDto,
): CancelablePromise<InvoiceDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Dashboard/invoice/{invoiceUuid}',
      path: {
        'invoiceUuid': invoiceUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param invoiceUuid 
   * @returns DashboardCountResponseDto Success
   * @throws ApiError
   */
  public deleteInvoice(
invoiceUuid: string,
): CancelablePromise<DashboardCountResponseDto> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Dashboard/invoice/{invoiceUuid}',
      path: {
        'invoiceUuid': invoiceUuid,
      },
    });
  }

  /**
   * @param projectUuid 
   * @param requestBody 
   * @returns number Success
   * @throws ApiError
   */
  public updateInvoiceLines(
projectUuid: string,
requestBody?: InvoiceTableDto,
): CancelablePromise<Array<number>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Dashboard/invoicelines/{projectUuid}',
      path: {
        'projectUuid': projectUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param projectUuid 
   * @returns LineMatchingTableDto Success
   * @throws ApiError
   */
  public getLineMatchingTable(
projectUuid: string,
): CancelablePromise<LineMatchingTableDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard/linematching/{projectUuid}',
      path: {
        'projectUuid': projectUuid,
      },
    });
  }

  /**
   * @param projectUuid 
   * @param requestBody 
   * @returns DashboardCountResponseDto Success
   * @throws ApiError
   */
  public updateLineMatchingTable(
projectUuid: string,
requestBody?: Array<LineMatchingRowUpdateDTO>,
): CancelablePromise<Array<DashboardCountResponseDto>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Dashboard/linematching/{projectUuid}',
      path: {
        'projectUuid': projectUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param projectUuid 
   * @param dateFromMonths 
   * @returns DifferenceTable Success
   * @throws ApiError
   */
  public getDifferenceTable(
projectUuid: string,
dateFromMonths?: number,
): CancelablePromise<DifferenceTable> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard/differencetable/{projectUuid}',
      path: {
        'projectUuid': projectUuid,
      },
      query: {
        'dateFromMonths': dateFromMonths,
      },
    });
  }

  /**
   * @param projectUuid 
   * @param format 
   * @returns any Success
   * @throws ApiError
   */
  public getDifferenceTableExport(
projectUuid: string,
format?: string,
): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard/{projectUuid}/differencetable/file',
      path: {
        'projectUuid': projectUuid,
      },
      query: {
        'format': format,
      },
    });
  }

  /**
   * @param projectUuid 
   * @param format 
   * @returns any Success
   * @throws ApiError
   */
  public getDifferenceTableExportMachine(
projectUuid: string,
format?: string,
): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard/{projectUuid}/differencetable/machine-file',
      path: {
        'projectUuid': projectUuid,
      },
      query: {
        'format': format,
      },
    });
  }

  /**
   * @param format 
   * @returns any Success
   * @throws ApiError
   */
  public getAllProjectsExport(
format?: string,
): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard/projects/differencetable/file',
      query: {
        'format': format,
      },
    });
  }

  /**
   * @param projectUuid 
   * @param invoiceLine 
   * @param dateFromMonths 
   * @returns DashboardGraph Success
   * @throws ApiError
   */
  public getGraph(
projectUuid: string,
invoiceLine?: string,
dateFromMonths?: number,
): CancelablePromise<DashboardGraph> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard/graph/{projectUuid}',
      path: {
        'projectUuid': projectUuid,
      },
      query: {
        'invoiceLine': invoiceLine,
        'dateFromMonths': dateFromMonths,
      },
    });
  }

  /**
   * @param projectUuid 
   * @returns Options Success
   * @throws ApiError
   */
  public getGraphOptions(
projectUuid: string,
): CancelablePromise<Options> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard/options/{projectUuid}',
      path: {
        'projectUuid': projectUuid,
      },
    });
  }

  /**
   * @param userId 
   * @param requestBody 
   * @returns CommentDto Success
   * @throws ApiError
   */
  public createComment(
userId?: string,
requestBody?: CommentCreateDto,
): CancelablePromise<CommentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Dashboard/comment',
      headers: {
        'User-Id': userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param commentUuid 
   * @param requestBody 
   * @returns CommentDto Success
   * @throws ApiError
   */
  public updateComment(
commentUuid: string,
requestBody?: CommentCreateDto,
): CancelablePromise<CommentDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Dashboard/comment/{commentUuid}',
      path: {
        'commentUuid': commentUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param commentUuid 
   * @returns DashboardCountResponseDto Success
   * @throws ApiError
   */
  public deleteComment(
commentUuid: string,
): CancelablePromise<DashboardCountResponseDto> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Dashboard/comment/{commentUuid}',
      path: {
        'commentUuid': commentUuid,
      },
    });
  }

  /**
   * @param projectUuid 
   * @returns CommentDto Success
   * @throws ApiError
   */
  public getComments(
projectUuid: string,
): CancelablePromise<Array<CommentDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard/comment/{projectUuid}',
      path: {
        'projectUuid': projectUuid,
      },
    });
  }

}
