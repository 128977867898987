/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateModelConfigsDto } from '../models/CreateModelConfigsDto';
import type { ModelConfigsDto } from '../models/ModelConfigsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ModelConfigsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param projectUuid 
   * @returns ModelConfigsDto Success
   * @throws ApiError
   */
  public getModelConfigs(
projectUuid?: string,
): CancelablePromise<Array<ModelConfigsDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/model-config',
      query: {
        'projectUuid': projectUuid,
      },
    });
  }

  /**
   * @param requestBody 
   * @returns ModelConfigsDto Success
   * @throws ApiError
   */
  public updateModelConfigs(
requestBody?: Array<ModelConfigsDto>,
): CancelablePromise<Array<ModelConfigsDto>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/model-config',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody 
   * @returns ModelConfigsDto Success
   * @throws ApiError
   */
  public createModelConfig(
requestBody?: CreateModelConfigsDto,
): CancelablePromise<ModelConfigsDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/model-config',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
