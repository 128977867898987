import React, { useEffect, useState } from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { useAuth0 } from "@auth0/auth0-react";
import { AUTH0_ORG_CLAIM_URL } from "../utils/auth/config";

interface LaunchDarklyProviderProps {
  children: React.ReactNode;
}

const LaunchDarklyProvider: React.FC<LaunchDarklyProviderProps> = ({ children }) => {
  const [LDProvider, setLDProvider] = useState<React.ComponentType | null>(null);
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    const initLD = async () => {
      if (isAuthenticated && user) {
        const clientSideID = import.meta.env.PROD
          ? import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID_PROD
          : import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID_DEV;

        try {
          const LDProvider = await asyncWithLDProvider({
            clientSideID,
            context: {
              kind: "user",
              key: user.email,
              email: user.email,
              org_name: user[AUTH0_ORG_CLAIM_URL],
            },
            options: {
              bootstrap: "localStorage",
            },
          });
          setLDProvider(() => LDProvider);
        } catch (error) {
          console.error("Failed to initialize LaunchDarkly:", error);
        }
      }
    };

    initLD();
  }, [isAuthenticated, user]);

  return LDProvider ? <LDProvider>{children}</LDProvider> : <>{children}</>;
};

export default LaunchDarklyProvider;
