/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileExtractionRunDto } from '../models/FileExtractionRunDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FileExtractionRunsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param fileUploadUuid 
   * @returns FileExtractionRunDto Success
   * @throws ApiError
   */
  public createFileExtractionRun(
fileUploadUuid: string,
): CancelablePromise<FileExtractionRunDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/file-extraction-runs/{fileUploadUuid}',
      path: {
        'fileUploadUuid': fileUploadUuid,
      },
    });
  }

}
