const config = {
  API_URL: import.meta.env.VITE_API_URL,
  API_URL_SHORT: import.meta.env.VITE_API_URL_SHORT,
  API_URL_BACKOFFICE: import.meta.env.VITE_API_URL_BACKOFFICE,
  API_VERSION: import.meta.env.VITE_API_VERSION,
  API_VERSION_BACKOFFICE: import.meta.env.VITE_API_VERSION_BACKOFFICE,
  API_WS_URL: import.meta.env.VITE_API_WS_URL,
  API_SIGNALR_URL: import.meta.env.VITE_API_SIGNALR_URL,
};

export default config;
