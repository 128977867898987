/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandDto } from '../models/BrandDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BrandsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns BrandDto Success
   * @throws ApiError
   */
  public getBrands(): CancelablePromise<Array<BrandDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Brands',
    });
  }

  /**
   * @param requestBody 
   * @returns BrandDto Success
   * @throws ApiError
   */
  public updateBrands(
requestBody?: Array<BrandDto>,
): CancelablePromise<Array<BrandDto>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Brands',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
