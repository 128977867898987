/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEmptySettingStatusDto } from '../models/CreateEmptySettingStatusDto';
import type { ProjectInvoiceLineDto } from '../models/ProjectInvoiceLineDto';
import type { SettingStatusDto } from '../models/SettingStatusDto';
import type { UpdateSettingStatusDto } from '../models/UpdateSettingStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SettingsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param dataKey 
   * @returns any Success
   * @throws ApiError
   */
  public getSettings(
dataKey?: string,
): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/settings',
      query: {
        'data_key': dataKey,
      },
    });
  }

  /**
   * @returns ProjectInvoiceLineDto Success
   * @throws ApiError
   */
  public getVendorStatements(): CancelablePromise<Record<string, Array<ProjectInvoiceLineDto>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/settings/vendor-statements',
    });
  }

  /**
   * @param projectUuid 
   * @param requestBody 
   * @returns ProjectInvoiceLineDto Success
   * @throws ApiError
   */
  public setVendorStatements(
projectUuid: string,
requestBody?: Array<ProjectInvoiceLineDto>,
): CancelablePromise<Array<ProjectInvoiceLineDto>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/settings/vendor-statements/{projectUuid}',
      path: {
        'projectUuid': projectUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SettingStatusDto Success
   * @throws ApiError
   */
  public getSettingStatuses(): CancelablePromise<Array<SettingStatusDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/settings/setting-statuses',
    });
  }

  /**
   * @param requestBody 
   * @returns SettingStatusDto Success
   * @throws ApiError
   */
  public createEmptySettingStatus(
requestBody?: CreateEmptySettingStatusDto,
): CancelablePromise<SettingStatusDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/settings/setting-status',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param uuid 
   * @param requestBody 
   * @returns SettingStatusDto Success
   * @throws ApiError
   */
  public updateSettingStatus(
uuid: string,
requestBody?: UpdateSettingStatusDto,
): CancelablePromise<SettingStatusDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/settings/setting-status/{uuid}',
      path: {
        'uuid': uuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
