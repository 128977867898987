/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModelsDto } from '../models/ModelsDto';
import type { ModelTemplateDto } from '../models/ModelTemplateDto';
import type { ModelUpdateDto } from '../models/ModelUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProxyModelConfigurationsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ModelTemplateDto Success
   * @throws ApiError
   */
  public getModelConfigurations(): CancelablePromise<Array<ModelTemplateDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/model-configurations/configurations',
    });
  }

  /**
   * @returns ModelTemplateDto Success
   * @throws ApiError
   */
  public getTemplates(): CancelablePromise<Array<ModelTemplateDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/model-configurations/templates',
    });
  }

  /**
   * @param projectUuid 
   * @returns ModelsDto Success
   * @throws ApiError
   */
  public getModel(
projectUuid: string,
): CancelablePromise<ModelsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/model-configurations/settings/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

  /**
   * @param modelUuid 
   * @param requestBody 
   * @returns ModelsDto Success
   * @throws ApiError
   */
  public updateModel(
modelUuid: string,
requestBody?: ModelUpdateDto,
): CancelablePromise<ModelsDto> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/model-configurations/settings/{model_uuid}',
      path: {
        'model_uuid': modelUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
