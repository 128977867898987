/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataMatchSettings } from '../models/DataMatchSettings';
import type { ReconciliationValidationConfig } from '../models/ReconciliationValidationConfig';
import type { ValidationConfigUpdateDto } from '../models/ValidationConfigUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ValidationConfigsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets the value of the setting key data_match_config (or null if not found)
   * @returns DataMatchSettings Success
   * @throws ApiError
   */
  public getMatchingSettings(): CancelablePromise<DataMatchSettings> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v2/data-match-settings',
    });
  }

  /**
   * Creates a new non default validation config and applies it to the given projects, overriding any existing config for the given projects
   * @param requestBody 
   * @returns ReconciliationValidationConfig Success
   * @throws ApiError
   */
  public createValidationConfig(
requestBody?: Array<string>,
): CancelablePromise<ReconciliationValidationConfig> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v2/data-match-settings/validation-config',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Updates the properties of a given validation config, without affecting nor returning the associated projects
   * @param configId 
   * @param requestBody 
   * @returns ReconciliationValidationConfig Success
   * @throws ApiError
   */
  public editValidationConfig(
configId: string,
requestBody?: ValidationConfigUpdateDto,
): CancelablePromise<ReconciliationValidationConfig> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/v2/data-match-settings/validation-config/{configId}',
      path: {
        'configId': configId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Deletes a given validation config, setting the applicable projects to have no config
   * @param configId 
   * @returns any Success
   * @throws ApiError
   */
  public deleteConfig(
configId: string,
): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/v2/data-match-settings/validation-config/{configId}',
      path: {
        'configId': configId,
      },
    });
  }

  /**
   * Associates a config to given projects, overriding any existing config that may exist for them.
   * @param configId 
   * @param requestBody 
   * @returns ReconciliationValidationConfig Success
   * @throws ApiError
   */
  public applyValidationToProject(
configId: string,
requestBody?: Array<string>,
): CancelablePromise<ReconciliationValidationConfig> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/v2/data-match-settings/validation-config/{configId}/projects',
      path: {
        'configId': configId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
