/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaskCommentCreateDto } from '../models/TaskCommentCreateDto';
import type { TaskDto } from '../models/TaskDto';
import type { TaskUpdateDto } from '../models/TaskUpdateDto';
import type { TaskWithEventsDto } from '../models/TaskWithEventsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TasksAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns TaskDto Success
   * @throws ApiError
   */
  public getTasks(): CancelablePromise<Array<TaskDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/tasks',
    });
  }

  /**
   * @param id 
   * @returns TaskWithEventsDto Success
   * @throws ApiError
   */
  public getTaskWithEvents(
id: string,
): CancelablePromise<TaskWithEventsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/tasks/{id}/events',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id 
   * @param requestBody 
   * @returns TaskWithEventsDto Success
   * @throws ApiError
   */
  public taskUpdate(
id: string,
requestBody?: TaskUpdateDto,
): CancelablePromise<TaskWithEventsDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/tasks/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param id 
   * @returns TaskWithEventsDto Success
   * @throws ApiError
   */
  public signTaskPreparer(
id: string,
): CancelablePromise<TaskWithEventsDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/tasks/{id}/preparer',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id 
   * @returns TaskWithEventsDto Success
   * @throws ApiError
   */
  public signTaskReviewer(
id: string,
): CancelablePromise<TaskWithEventsDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/tasks/{id}/reviewer',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id 
   * @param requestBody 
   * @returns TaskWithEventsDto Success
   * @throws ApiError
   */
  public taskCommentCreate(
id: string,
requestBody?: TaskCommentCreateDto,
): CancelablePromise<TaskWithEventsDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/tasks/{id}/comment',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
