/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiReconciliationTransaction } from '../models/ApiReconciliationTransaction';
import type { IFileHttpResult } from '../models/IFileHttpResult';
import type { ReconciliationExportTemplate } from '../models/ReconciliationExportTemplate';
import type { TransactionMatchingFilterOptionsDto } from '../models/TransactionMatchingFilterOptionsDto';
import type { TransactionMatchingSummaryDto } from '../models/TransactionMatchingSummaryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TransactionMatchingAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns the classification (number of rows per validation status) matching the given condition.
   * @param periodStart 
   * @param periodEnd 
   * @param id 
   * @param projects 
   * @param uploads 
   * @param connections 
   * @param pspStatus 
   * @param pboStatus 
   * @param pboMethod 
   * @param brandKeys 
   * @param filter 
   * @returns TransactionMatchingSummaryDto Success
   * @throws ApiError
   */
  public getSummary(
periodStart?: string,
periodEnd?: string,
id?: string,
projects?: string,
uploads?: string,
connections?: string,
pspStatus?: string,
pboStatus?: string,
pboMethod?: string,
brandKeys?: string,
filter?: string,
): CancelablePromise<TransactionMatchingSummaryDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v2/data-match/summary',
      query: {
        'period_start': periodStart,
        'period_end': periodEnd,
        'id': id,
        'projects': projects,
        'uploads': uploads,
        'connections': connections,
        'psp_status': pspStatus,
        'pbo_status': pboStatus,
        'pbo_method': pboMethod,
        'brand_keys': brandKeys,
        'filter': filter,
      },
    });
  }

  /**
   * The main endpoint used for listing
   * @param periodStart 
   * @param periodEnd 
   * @param id 
   * @param projects 
   * @param uploads 
   * @param connections 
   * @param pspStatus 
   * @param pboStatus 
   * @param pboMethod 
   * @param brandKeys 
   * @param filter 
   * @param offset 
   * @param limit 
   * @returns ApiReconciliationTransaction Success
   * @throws ApiError
   */
  public getTransactions(
periodStart?: string,
periodEnd?: string,
id?: string,
projects?: string,
uploads?: string,
connections?: string,
pspStatus?: string,
pboStatus?: string,
pboMethod?: string,
brandKeys?: string,
filter?: string,
offset?: number,
limit: number = 100,
): CancelablePromise<Array<ApiReconciliationTransaction>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v2/data-match',
      query: {
        'period_start': periodStart,
        'period_end': periodEnd,
        'id': id,
        'projects': projects,
        'uploads': uploads,
        'connections': connections,
        'psp_status': pspStatus,
        'pbo_status': pboStatus,
        'pbo_method': pboMethod,
        'brand_keys': brandKeys,
        'filter': filter,
        'offset': offset,
        'limit': limit,
      },
    });
  }

  /**
   * Returns the available/possible values to choose form the drop-down filter
   * @param periodStart 
   * @param periodEnd 
   * @param id 
   * @param projects 
   * @param uploads 
   * @param connections 
   * @param pspStatus 
   * @param pboStatus 
   * @param pboMethod 
   * @param brandKeys 
   * @param filter 
   * @returns TransactionMatchingFilterOptionsDto Success
   * @throws ApiError
   */
  public getPossibleFilterValues(
periodStart?: string,
periodEnd?: string,
id?: string,
projects?: string,
uploads?: string,
connections?: string,
pspStatus?: string,
pboStatus?: string,
pboMethod?: string,
brandKeys?: string,
filter?: string,
): CancelablePromise<TransactionMatchingFilterOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v2/data-match/filters',
      query: {
        'period_start': periodStart,
        'period_end': periodEnd,
        'id': id,
        'projects': projects,
        'uploads': uploads,
        'connections': connections,
        'psp_status': pspStatus,
        'pbo_status': pboStatus,
        'pbo_method': pboMethod,
        'brand_keys': brandKeys,
        'filter': filter,
      },
    });
  }

  /**
   * Returns a csv or excel file with the transactions matching the given condition.
   * @param periodStart 
   * @param periodEnd 
   * @param id 
   * @param projects 
   * @param uploads 
   * @param connections 
   * @param pspStatus 
   * @param pboStatus 
   * @param pboMethod 
   * @param brandKeys 
   * @param filter 
   * @param downloadType 
   * @returns IFileHttpResult Success
   * @throws ApiError
   */
  public download(
periodStart?: string,
periodEnd?: string,
id?: string,
projects?: string,
uploads?: string,
connections?: string,
pspStatus?: string,
pboStatus?: string,
pboMethod?: string,
brandKeys?: string,
filter?: string,
downloadType?: ReconciliationExportTemplate,
): CancelablePromise<IFileHttpResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v2/data-match/download',
      query: {
        'period_start': periodStart,
        'period_end': periodEnd,
        'id': id,
        'projects': projects,
        'uploads': uploads,
        'connections': connections,
        'psp_status': pspStatus,
        'pbo_status': pboStatus,
        'pbo_method': pboMethod,
        'brand_keys': brandKeys,
        'filter': filter,
        'downloadType': downloadType,
      },
    });
  }

}
