import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import Maintenance from "../pages/Maintenance";

interface LaunchDarklyProviderProps {
  children: React.ReactNode;
}

const MaintenanceProvider: React.FC<LaunchDarklyProviderProps> = ({ children }) => {
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false);
  const { maintenanceModeForAllOrganizations } = useFlags();

  useEffect(() => {
    if (maintenanceModeForAllOrganizations) {
      setIsMaintenance(true);
    } else {
      setIsMaintenance(false);
    }
  }, [maintenanceModeForAllOrganizations]);

  return isMaintenance ? <Maintenance /> : <>{children}</>;
};

export default MaintenanceProvider;
