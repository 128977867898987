/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CloneModelDto } from '../models/CloneModelDto';
import type { ModelCountResponse } from '../models/ModelCountResponse';
import type { ModelsDto } from '../models/ModelsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ModelsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody 
   * @returns ModelCountResponse Success
   * @throws ApiError
   */
  public cloneModel(
requestBody?: CloneModelDto,
): CancelablePromise<ModelCountResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Models/clone',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param modelUuid 
   * @returns ModelsDto Success
   * @throws ApiError
   */
  public deleteModel(
modelUuid: string,
): CancelablePromise<ModelsDto> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Models/{modelUuid}',
      path: {
        'modelUuid': modelUuid,
      },
    });
  }

}
