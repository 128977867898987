/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenerateSettlementDto } from '../models/GenerateSettlementDto';
import type { SettlementDto } from '../models/SettlementDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SettlementsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody 
   * @returns any Success
   * @throws ApiError
   */
  public generateSettlementStatements(
requestBody?: GenerateSettlementDto,
): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Settlements',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param startDate 
   * @param endDate 
   * @returns SettlementDto Success
   * @throws ApiError
   */
  public getAllSettlements(
startDate?: string,
endDate?: string,
): CancelablePromise<Array<SettlementDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Settlements',
      query: {
        'startDate': startDate,
        'endDate': endDate,
      },
    });
  }

  /**
   * @param startDate 
   * @param endDate 
   * @param projectUuids 
   * @param sourceType 
   * @returns any Success
   * @throws ApiError
   */
  public downloadSettlementStatement(
startDate?: string,
endDate?: string,
projectUuids?: Array<string>,
sourceType?: string,
): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Settlements/download',
      query: {
        'startDate': startDate,
        'endDate': endDate,
        'projectUuids': projectUuids,
        'sourceType': sourceType,
      },
    });
  }

}
