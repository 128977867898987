/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExtractionConfigPairDto } from '../models/ExtractionConfigPairDto';
import type { FileExtractionConfigDto } from '../models/FileExtractionConfigDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FileExtractionConfigsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns FileExtractionConfigDto Success
   * @throws ApiError
   */
  public getAllExtractionConfigs(): CancelablePromise<Array<FileExtractionConfigDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/file-extraction-configs',
    });
  }

  /**
   * @param configUuid 
   * @returns ExtractionConfigPairDto Success
   * @throws ApiError
   */
  public getExtractionConfig(
configUuid: string,
): CancelablePromise<ExtractionConfigPairDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/file-extraction-configs/{configUuid}',
      path: {
        'configUuid': configUuid,
      },
    });
  }

  /**
   * @param configUuid 
   * @param requestBody 
   * @returns FileExtractionConfigDto Success
   * @throws ApiError
   */
  public updateExtractionConfig(
configUuid: string,
requestBody?: FileExtractionConfigDto,
): CancelablePromise<FileExtractionConfigDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/file-extraction-configs/{configUuid}',
      path: {
        'configUuid': configUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
