import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { SignalRContext } from "./SignalRContext";
import config from "../core/config";
import { useAuth0 } from "@auth0/auth0-react";
import { accessTokenRequest } from "../core/auth";

// Provider component that manages the SignalR connection
export const SignalRProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [connected, setConnected] = useState<boolean>(false);

  useEffect(() => {
    const connectToSignalR = async () => {
      const newConnection = new HubConnectionBuilder()
        .withUrl(config.API_SIGNALR_URL, {
          accessTokenFactory: async () => {
            const token = await getAccessTokenSilently(accessTokenRequest);
            return token;
          },
        }) // Add URL and options here
        .withAutomaticReconnect() // Optional: Automatically reconnect if connection is lost
        .build();

      setConnection(newConnection);

      newConnection.onreconnecting(error => {
        console.log("Reconnecting...", error);
        setConnected(false);
      });

      newConnection.onreconnected(() => {
        console.log("Reconnected!");
        setConnected(true);
      });

      newConnection.onclose(error => {
        console.log("Connection closed...", error);
        setConnected(false);
      });

      try {
        console.log("Connecting to SignalR...");
        await newConnection.start();
        console.log("SignalR Connected.");
        setConnected(true);
      } catch (err) {
        console.log("SignalR Connection Error: ", err);
      }
    };

    if (isAuthenticated) {
      connectToSignalR();
    } else if (!isAuthenticated && connection) {
      connection.stop();
      setConnected(false);
      setConnection(null);
    }

    // Clean up the connection on component unmount
    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [isAuthenticated]);

  // Provide connection state and methods to child components

  return (
    <SignalRContext.Provider value={{ connection, connected }}>{children}</SignalRContext.Provider>
  );
};
