/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TransactionGroupDto } from '../models/TransactionGroupDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TransactionGroupsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns TransactionGroupDto Success
   * @throws ApiError
   */
  public getTransactionGroups(): CancelablePromise<Array<TransactionGroupDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/transaction-groups',
    });
  }

  /**
   * @returns TransactionGroupDto Success
   * @throws ApiError
   */
  public getTransactionGroupsWithTransactionCount(): CancelablePromise<Array<TransactionGroupDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/transaction-groups/count',
    });
  }

  /**
   * @param transactionGroupUuid 
   * @param requestBody 
   * @returns TransactionGroupDto Success
   * @throws ApiError
   */
  public updateTransactionGroup(
transactionGroupUuid: string,
requestBody?: TransactionGroupDto,
): CancelablePromise<TransactionGroupDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/transaction-groups/{transaction_group_uuid}',
      path: {
        'transaction_group_uuid': transactionGroupUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
